var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.save()}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('roulettes.rouletteFisic.type'))+": ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.doubleZero),expression:"typeform.doubleZero"}],staticClass:"form-select border-0 shadow-lg",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "doubleZero", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{domProps:{"value":false}},[_vm._v("Ruleta - Sencilla")]),_c('option',{domProps:{"value":true}},[_vm._v("Ruleta - 00")])])]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t('roulettes.name')))]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.name.$error,
            },attrs:{"placeholder":"Nombre","border":""},model:{value:(_vm.typeform.name),callback:function ($$v) {_vm.$set(_vm.typeform, "name", $$v)},expression:"typeform.name"}}),(_vm.typesubmit && _vm.$v.typeform.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.name.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t('roulettes.rouletteFisic.providerId')))]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.providerId.$error,
            },attrs:{"placeholder":_vm.$t('roulettes.rouletteFisic.providerId'),"border":""},model:{value:(_vm.typeform.providerId),callback:function ($$v) {_vm.$set(_vm.typeform, "providerId", $$v)},expression:"typeform.providerId"}}),(_vm.typesubmit && _vm.$v.typeform.providerId.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.providerId.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t('roulettes.rouletteFisic.roundDuration')))]),_c('vs-input',{staticClass:"shadow-lg",class:{
              'is-invalid': _vm.typesubmit && _vm.$v.typeform.roundDuration.$error,
            },attrs:{"placeholder":_vm.$t('roulettes.rouletteFisic.roundDuration'),"border":""},model:{value:(_vm.typeform.roundDuration),callback:function ($$v) {_vm.$set(_vm.typeform, "roundDuration", $$v)},expression:"typeform.roundDuration"}}),(_vm.typesubmit && _vm.$v.typeform.roundDuration.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.roundDuration.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t('roulettes.rouletteFisic.minutesDisabled')))]),_c('vs-input',{staticClass:"shadow-lg",attrs:{"placeholder":_vm.$t('roulettes.rouletteFisic.minutesDisabled'),"border":"","name":"minutesToDisable"},model:{value:(_vm.typeform.minutesToDisable),callback:function ($$v) {_vm.$set(_vm.typeform, "minutesToDisable", $$v)},expression:"typeform.minutesToDisable"}})],1)]),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('roulettes.rouletteFisic.video')))]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.urlTransmision.$error,
          },attrs:{"placeholder":_vm.$t('roulettes.rouletteFisic.video'),"border":""},model:{value:(_vm.typeform.urlTransmision),callback:function ($$v) {_vm.$set(_vm.typeform, "urlTransmision", $$v)},expression:"typeform.urlTransmision"}}),(_vm.typesubmit && _vm.$v.typeform.urlTransmision.$error)?_c('div',{staticClass:"invalid-feedback"}):_vm._e()],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('roulettes.rouletteFisic.time1')))]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.timeOne.$error,
          },attrs:{"placeholder":_vm.$t('roulettes.rouletteFisic.time1'),"border":""},model:{value:(_vm.typeform.timeOne),callback:function ($$v) {_vm.$set(_vm.typeform, "timeOne", $$v)},expression:"typeform.timeOne"}}),(_vm.typesubmit && _vm.$v.typeform.timeOne.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.timeOne.numeric)?_c('span',[_vm._v("Este campo es numerico")]):_vm._e(),(!_vm.$v.typeform.timeOne.required)?_c('span',[_vm._v("Este campo es necesario")]):_vm._e()]):_vm._e()],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('roulettes.rouletteFisic.time2')))]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.timeTwo.$error,
          },attrs:{"placeholder":_vm.$t('roulettes.rouletteFisic.time2'),"border":""},model:{value:(_vm.typeform.timeTwo),callback:function ($$v) {_vm.$set(_vm.typeform, "timeTwo", $$v)},expression:"typeform.timeTwo"}}),(_vm.typesubmit && _vm.$v.typeform.timeTwo.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.timeTwo.numeric)?_c('span',[_vm._v("Este campo es numerico")]):_vm._e(),(!_vm.$v.typeform.timeTwo.required)?_c('span',[_vm._v("Este campo es necesario")]):_vm._e()]):_vm._e()],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('roulettes.rouletteFisic.time3')))]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.timeThree.$error,
          },attrs:{"placeholder":_vm.$t('roulettes.rouletteFisic.time3'),"border":""},model:{value:(_vm.typeform.timeThree),callback:function ($$v) {_vm.$set(_vm.typeform, "timeThree", $$v)},expression:"typeform.timeThree"}}),(_vm.typesubmit && _vm.$v.typeform.timeThree.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.timeThree.numeric)?_c('span',[_vm._v("Este campo es numerico")]):_vm._e(),(!_vm.$v.typeform.timeThree.required)?_c('span',[_vm._v("Este campo es necesario")]):_vm._e()]):_vm._e()],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('roulettes.rouletteFisic.time4')))]),_c('vs-input',{staticClass:"shadow-lg",attrs:{"placeholder":_vm.$t('roulettes.rouletteFisic.time4'),"border":""},model:{value:(_vm.typeform.timeFour),callback:function ($$v) {_vm.$set(_vm.typeform, "timeFour", $$v)},expression:"typeform.timeFour"}})],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('roulettes.rouletteFisic.aditionalTime')))]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.aditionalTime.$error,
          },attrs:{"placeholder":_vm.$t('roulettes.rouletteFisic.aditionalTime'),"border":""},model:{value:(_vm.typeform.aditionalTime),callback:function ($$v) {_vm.$set(_vm.typeform, "aditionalTime", $$v)},expression:"typeform.aditionalTime"}}),(_vm.typesubmit && _vm.$v.typeform.aditionalTime.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.aditionalTime.numeric)?_c('span',[_vm._v("Este campo es numerico")]):_vm._e(),(!_vm.$v.typeform.aditionalTime.required)?_c('span',[_vm._v("Este campo es necesario")]):_vm._e()]):_vm._e()],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('roulettes.rouletteFisic.timeReleaseJackpot')))]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid': _vm.typesubmit && _vm.$v.typeform.timeToReleaseJack.$error,
          },attrs:{"placeholder":_vm.$t('roulettes.rouletteFisic.timeReleaseJackpot'),"border":""},model:{value:(_vm.typeform.timeToReleaseJack),callback:function ($$v) {_vm.$set(_vm.typeform, "timeToReleaseJack", $$v)},expression:"typeform.timeToReleaseJack"}}),(_vm.typesubmit && _vm.$v.typeform.timeToReleaseJack.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.timeToReleaseJack.required)?_c('span',[_vm._v("Este campo es necesario")]):_vm._e(),(!_vm.$v.typeform.timeToReleaseJack.numeric)?_c('span',[_vm._v("Este campo es numerico")]):_vm._e()]):_vm._e()],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('label',[_vm._v(_vm._s(_vm.$t('roulettes.rouletteFisic.timeToStartAnimation')))]),_c('vs-input',{staticClass:"shadow-lg",class:{
            'is-invalid':
              _vm.typesubmit && _vm.$v.typeform.timeToStartAnimation.$error,
          },attrs:{"placeholder":_vm.$t('roulettes.rouletteFisic.timeToStartAnimation'),"border":""},model:{value:(_vm.typeform.timeToStartAnimation),callback:function ($$v) {_vm.$set(_vm.typeform, "timeToStartAnimation", $$v)},expression:"typeform.timeToStartAnimation"}}),(_vm.typesubmit && _vm.$v.typeform.timeToStartAnimation.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.timeToStartAnimation.required)?_c('span',[_vm._v("Este campo es necesario")]):_vm._e(),(!_vm.$v.typeform.timeToStartAnimation.numeric)?_c('span',[_vm._v("Este campo es numerico")]):_vm._e()]):_vm._e()],1),_c('b-col',{attrs:{"sm":"12","md":"6"}},[_c('div',{staticClass:"mb-3"},[_c('label',[_vm._v(_vm._s(_vm.$t('roulettes.rouletteFisic.crupier')))]),_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.typeform.crupier),expression:"typeform.crupier"}],staticClass:"form-select border-0 shadow-lg",class:{
                'is-invalid': _vm.typesubmit && _vm.$v.typeform.crupier.$error,
              },attrs:{"name":"crupier","placeholder":"Seleccione.."},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.typeform, "crupier", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"disabled":"","selected":""}},[_vm._v(_vm._s(_vm.$t('helpers.select'))+"...")]),_vm._l((_vm.crupiers),function(item,index){return _c('option',{key:index,domProps:{"value":item._id,"selected":_vm.typeform.crupier == item._id}},[_vm._v(" "+_vm._s(item.name)+" ")])})],2),(_vm.typesubmit && _vm.$v.typeform.crupier.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.typeform.crupier.required)?_c('span',[_vm._v(" Este campo es requerido. ")]):_vm._e()]):_vm._e()])])])],1),_c('b-row',{staticClass:"mb-4 mt-3"},[_c('b-form-checkbox',{attrs:{"checked":_vm.getJackpot},on:{"change":_vm.handleChange}},[_vm._v(" Jackpot ")])],1),_c('div',{staticClass:"mb-3 mb-0"},[_c('div',{staticClass:"d-flex"},[_c('vs-button',{attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('form.save')))]),_c('vs-button',{attrs:{"type":"button","success":""},on:{"click":function($event){return _vm.$emit('closeModa')}}},[_vm._v(" "+_vm._s(_vm.$t('form.abort'))+" ")])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }